import * as HTTP from '@/modules/helpers/http'

import {
  PriorAuthSubmissionWithStatus,
  PriorAuthSubmission,
} from '@/types/api/prior-auth/submission'
import { getBFFRoute } from '@/modules/helpers/http'
import { mapAttachment } from '@/mappers/wizard/attachment'
import { AttachmentType } from '@/types/form/prior-auth/attachment'
import axios from 'axios'

export const removeNullValues = (_: string | number, value: unknown) =>
  value !== null ? value : undefined

const blobify = (value: unknown) =>
  new Blob([JSON.stringify(value, removeNullValues)], { type: 'application/json' })

const constructRequest = (data: PriorAuthSubmissionWithStatus): FormData => {
  const formData = new FormData()
  formData.append(
    'submission',
    blobify({ submission: data.submission, status: data.status }),
    'submission.json'
  )
  if (data?.status) {
    formData.append('status', blobify({ status: data.status }), 'status.json')
  }
  return formData
}

const postNewProviderBatch = async (
  jwt: string,
  clientId: string,
  csrf: string,
  providers: any[]
) => {
  const response = await axios.post(`${getBFFRoute()}/api/providers/batch`, providers, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'Content-Type': 'application/json',
      'x-csrf-token': csrf,
    },
  })
  return response?.data || null
}

const fetchProvider = (jwt: string, clientId: string, csrf: string, type: string, npi: string) => {
  return HTTP.createGetRequest(`${getBFFRoute()}/api/providers`, {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'x-csrf-token': csrf,
    },
    params: { type, npi },
  })
}

const fetchPayerList = async (jwt: string, clientId: string, csrf: string) => {
  const path = `${getBFFRoute()}/api/payers`
  const response = await axios.get(path, {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'x-csrf-token': csrf,
    },
  })
  return response?.data
}

const fetchCategoriesList = async (jwt: string, clientId: string, csrf: string) => {
  const path = `${getBFFRoute()}/api/healthcare_categories`
  const response = await axios.get(path, {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'x-csrf-token': csrf,
    },
  })
  return response?.data
}

async function postSubmissionAttachment(
  jwt: string,
  clientId: string,
  csrf: string,
  submissionId: string,
  attachmentType: AttachmentType,
  attachment: FormData
) {
  const url = `${getBFFRoute()}/api/submissions/${submissionId}/attachment`
  return await axios
    .post(url, attachment, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'x-myndshft-client-id': clientId,
        'content-type': 'multipart/form-data',
        'x-csrf-token': csrf,
      },
      params: { attachment_type: attachmentType },
    })
    .then(response => {
      return mapAttachment(response.data) || {}
    })
}

async function postDraft(
  jwt: string,
  submission: PriorAuthSubmission,
  clientId: string,
  csrf: string,
  correlationId: string
) {
  try {
    const path = `${getBFFRoute()}/api/draft`

    const formData = constructRequest({
      submission: submission.submission,
      status: 'draft',
    } as PriorAuthSubmissionWithStatus)

    const response = await axios.post(path, formData, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'x-myndshft-client-id': clientId,
        'x-myndshft-correlation-id': correlationId,
        'content-type': 'multipart/form-data',
        'x-csrf-token': csrf,
      },
    })

    return response?.data
  } catch (error) {
    console.error('Failed to save initial draft')
  }
}

async function putDraft(
  jwt: string,
  data: PriorAuthSubmission,
  submissionId: string,
  clientId: string,
  csrf: string,
  correlationId: string
) {
  try {
    const path = `${getBFFRoute()}/api/submissions/${submissionId}`

    const response = await axios.put(path, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'x-myndshft-client-id': clientId,
        'x-myndshft-correlation-id': correlationId,
        'content-type': 'application/json',
        'x-csrf-token': csrf,
      },
    })

    return response?.data
  } catch (error) {
    console.error('Draft save failed')
    throw error
  }
}

async function postSubmission(
  jwt: string,
  clientId: string,
  submissionId: string,
  correlationId: string,
  csrf: string,
  data: PriorAuthSubmission
) {
  try {
    const path = `${getBFFRoute()}/api/submissions/${submissionId}`

    const response = await axios.post(path, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'x-myndshft-client-id': clientId,
        'x-myndshft-correlation-id': correlationId,
        'content-type': 'application/json',
        'x-csrf-token': csrf,
      },
    })
    return response?.data?.id
  } catch (error) {
    console.error('Submission failed')
    throw error
  }
}

const fetchEncounterPropertiesByProcedure = async (
  jwt: string,
  clientId: string,
  csrf: string,
  correlationId: string,
  code: string,
  questionnaireResponseId?: string
) => {
  try {
    const path = `${getBFFRoute()}/api/encounters/${correlationId}/codes/${code}/properties`
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'x-myndshft-client-id': clientId,
      'x-myndshft-correlation-id': correlationId,
      'content-type': 'application/json',
      'x-csrf-token': csrf,
    }

    if (questionnaireResponseId) {
      headers['x-questionnaire-response-id'] = questionnaireResponseId
    }

    const response = await axios.get(path, {
      headers,
      params: {
        code,
      },
    })

    return response?.data
  } catch (error) {
    console.error('Fetching encounter properties failed')
    throw error
  }
}

const fetchTenantConfigSettings = async (csrf: string) =>
  await axios.get(`${getBFFRoute()}/api/tenant_settings`, {
    headers: {
      'x-csrf-token': csrf,
    },
    responseType: 'json',
  })

export const useSubmissionApi = (jwt: string, clientId: string, csrf: string) => ({
  submitPriorAuth: async (subId: string, correlationId: string, data: PriorAuthSubmission) =>
    await postSubmission(jwt, clientId, subId, correlationId, csrf, data),
  postNewDraft: (correlationId: string, submission: PriorAuthSubmission) =>
    postDraft(jwt, submission, clientId, csrf, correlationId),
  saveDraft: (correlationId: string, subId: string, data: PriorAuthSubmission) =>
    putDraft(jwt, data, subId, clientId, csrf, correlationId),
  getPayerList: async () => await fetchPayerList(jwt, clientId, csrf),
  getProvider: (type: string, npi: string) => fetchProvider(jwt, clientId, csrf, type, npi),
  uploadNewProviderBatch: async (payload: any[]) =>
    await postNewProviderBatch(jwt, clientId, csrf, payload),
  getTenantConfigSettings: async () => await fetchTenantConfigSettings(csrf),
  getHealthCareCategories: async () => await fetchCategoriesList(jwt, clientId, csrf),
  getEncounterConfigurationProperties: (
    correlationId: string,
    code: string,
    questionnaireResponseId?: string
  ) =>
    fetchEncounterPropertiesByProcedure(
      jwt,
      clientId,
      csrf,
      correlationId,
      code,
      questionnaireResponseId
    ),
  uploadAttachment: (submissionId: string, attachmentType: AttachmentType, attachment: FormData) =>
    postSubmissionAttachment(jwt, clientId, csrf, submissionId, attachmentType, attachment),
})
